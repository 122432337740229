<template>
  <b-container class="authPages">
    <b-row>
      <b-col lg="8" offset-lg="2">
        <article class="single-blog contact-us">
          <div class="post-content">
            <div class="">
              <h3 class="mt-2">New or forgotten password</h3>

              <b-alert variant="danger" :show="error.length > 0"
                >{{ error }}
              </b-alert>

              <p class="p-2">
                Enter your email address to set a new password. Email
                address must be the same as the one you used to register.
              </p>

              <b-form class="form-horizontal contact-form">
                <b-form-group>
                  <b-input
                    v-model="username"
                    type="text"
                    id="email"
                    name="email"
                    placeholder="username (email)"
                    required=""
                  />
                </b-form-group>
                <b-button
                  type="submit"
                  class="btn send-btn mb-4 mt-2"
                  @click.prevent.stop="login"
                  >RESET PASSWORD
                </b-button>

                <div class="mt-2">
                  <a
                    class="btn btn-link"
                    @click.prevent.stop="
                      () => {
                        $router.push({ name: 'EN_SignIn' });
                      }
                    "
                    >To the sign-in form</a
                  >
                </div>
                <div class="mt-2">
                  <a
                    class="btn btn-link"
                    @click.prevent.stop="
                      () => {
                        $router.push({ name: 'EN_Register' });
                      }
                    "
                    >Not registered yet?</a
                  >
                </div>
              </b-form>
            </div>
          </div>
        </article>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// @ is an alias to /src
import firebase from "firebase";
import { db } from "../../main";

export default {
  name: "EN_ForgottenPassword",
  components: {},
  data() {
    return {
      username: "",
      password: "",
      error: "",
      user: {}
    };
  },
  beforeCreate() {
    const selectedLang = localStorage.getItem('zapisi_spomina_language');
    if(selectedLang !== null) {
      if(selectedLang === 'EN' && !this.$route.fullPath.includes('/en')) {
        this.$router.push({name: 'EN_' + this.$route.name}, this.$route.params);
      } else if(selectedLang === 'SI' && this.$route.fullPath.includes('/en')) {
        this.$router.push({name: this.$route.name.split('EN_')[1]}, this.$route.params);
      }
    }
  },
  mounted() {},
  methods: {
    login() {
      const _this = this;
      console.log("login", _this);

      firebase
        .auth()
        .sendPasswordResetEmail(_this.username)
        .then(data => {
          console.log("data", data);

          _this.$swal(
            "To the email address " +
              _this.username +
              " we have sent you a link to reset your password.",
            "",
            "success"
          );
        })
        .catch(err => {
          console.log("error", err);
          _this.error =
            "Resetting your user account password " +
            _this.username +
            " is not possible!";
        });
    },

    updateUser(data, recordId) {
      const _this = this;

      console.log("recordId", recordId);

      db.collection("users")
        .doc(recordId)
        .update({
          lastLoginAt: new Date()
        })
        .then(function() {
          console.log("Record updated");
          _this.$router.push({ name: "EN_MyPosts" });
        })
        .catch(function(error) {
          console.error("Error adding Record: ", error);
        });
    }
  }
};
</script>
