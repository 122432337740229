<template>
  <b-container class="authPages">
    <b-row>
      <b-col lg="8" offset-lg="2">
        <article class="single-blog contact-us">
          <div class="post-content">
            <div>
              <h3 class="mt-2">Registracija</h3>

              <b-alert variant="danger" :show="error.length > 0"
                >{{ error }}
              </b-alert>

              <b-form
                class="form-horizontal contact-form"
                @submit.prevent="register"
              >
                <b-form-group>
                  <b-input
                    v-model.trim="firstName"
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="Ime"
                    required=""
                  />

                  <div v-if="$v.firstName.$dirty">
                    <b-alert
                      show
                      variant="danger"
                      class="error col mt-1"
                      v-if="!$v.firstName.required"
                    >
                      Polje "Ime" ne sme biti prazno.
                    </b-alert>
                    <b-alert
                      show
                      variant="danger"
                      class="error col mt-1"
                      v-if="!$v.firstName.minLength"
                    >
                      Polje "Ime" mora vsebovati vsaj
                      {{ $v.firstName.$params.minLength.min }} črke.
                    </b-alert>
                  </div>
                </b-form-group>
                <b-form-group>
                  <b-input
                    v-model.trim="lastName"
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="Priimek"
                    required=""
                  />

                  <div v-if="$v.lastName.$dirty">
                    <b-alert
                      show
                      variant="danger"
                      class="error col mt-1"
                      v-if="!$v.lastName.required"
                    >
                      Polje "Priimek" ne sme biti prazno.
                    </b-alert>
                    <b-alert
                      show
                      variant="danger"
                      class="error col mt-1"
                      v-if="!$v.lastName.minLength"
                    >
                      Polje "Priimek" mora vsebovati vsaj
                      {{ $v.lastName.$params.minLength.min }} črke.
                    </b-alert>
                  </div>
                </b-form-group>
                <b-form-group>
                  <b-input
                    v-model.trim="displayName"
                    type="text"
                    id="displayName"
                    name="displayName"
                    placeholder="Prikazno ime oz. psevdonim (to ime bo vidno ob vaših objavah)"
                    required=""
                  />

                  <div v-if="$v.displayName.$dirty">
                    <b-alert
                      show
                      variant="danger"
                      class="error col mt-1"
                      v-if="!$v.displayName.required"
                    >
                      Polje "Prikazno ime (psevdonim)" ne sme biti prazno.
                    </b-alert>
                    <b-alert
                      show
                      variant="danger"
                      class="error col mt-1"
                      v-if="!$v.displayName.minLength"
                    >
                      Polje "Prikazno ime (psevdonim)" mora vsebovati vsaj
                      {{ $v.displayName.$params.minLength.min }} črke.
                    </b-alert>
                  </div>
                </b-form-group>
                <b-form-group>
                  <b-input
                    v-model.trim="yearOfBirth"
                    type="number"
                    step="1"
                    min="1900"
                    :max="moment().format('YYYY')"
                    id="yearOfBirth"
                    name="yearOfBirth"
                    placeholder="Letnica rojstva (npr. 1970)"
                    required=""
                    @blur="calculateAge"
                  />
                  <div v-if="$v.yearOfBirth.$dirty">
                    <b-alert
                      show
                      variant="danger"
                      class="error col mt-1"
                      v-if="!$v.yearOfBirth.required"
                    >
                      Polje "Letnica rojstva" ne sme biti prazno.
                    </b-alert>
                    <b-alert
                      show
                      variant="danger"
                      class="error col mt-1"
                      v-if="!$v.yearOfBirth.numeric"
                    >
                      Polje "Letnica rojstva" lahko vsebuje samo števila.
                    </b-alert>
                    <b-alert
                      show
                      variant="danger"
                      class="error col mt-1"
                      v-if="!$v.yearOfBirth.minValue"
                    >
                      Polje "Letnica rojstva" mora biti poznejša od leta
                      {{ $v.yearOfBirth.$params.minValue.min }}.
                    </b-alert>
                    <b-alert
                      show
                      variant="danger"
                      class="error col mt-1"
                      v-if="!$v.yearOfBirth.maxValue"
                    >
                      Polje "Letnica rojstva" ne sme biti poznejša od leta
                      {{ $v.yearOfBirth.$params.maxValue.max }}.
                    </b-alert>
                    <b-alert
                      show
                      variant="danger"
                      class="error col mt-1"
                      v-if="!$v.yearOfBirth.minLength"
                    >
                      Polje "Letnica rojstva" mora vsebovati vsaj
                      {{ $v.yearOfBirth.$params.minLength.min }} številke.
                    </b-alert>
                  </div>
                </b-form-group>
                <b-card
                  class="my-3"
                  style="border-radius: 20px;"
                  v-if="isMinor"
                >
                  <b-alert show variant="info"
                    >Mladoletne osebe so ob registraciji zakonsko dolžne
                    posredovati podatke o staršu oz. skrbniku.
                  </b-alert>
                  <b-form-group>
                    <b-input
                      v-model.trim="parentsName"
                      type="text"
                      id="parentsName"
                      name="parentsName"
                      placeholder="Ime in priimek starša oz. skbrnika"
                      required=""
                    />
                    <div v-if="$v.parentsName.$dirty">
                      <b-alert
                        show
                        variant="danger"
                        class="error col mt-1"
                        v-if="!$v.parentsName.required"
                      >
                        Polje "Ime in priimek starša oz. skbrnika" ne sme biti
                        prazno.
                      </b-alert>
                    </div>
                  </b-form-group>

                  <b-form-group class="mb-0">
                    <b-input
                      v-model="parentsEmail"
                      type="email"
                      id="parentsEmail"
                      name="parentsEmail"
                      placeholder="email naslov starša oz. skrbnika"
                      required=""
                    />
                    <div v-if="$v.parentsEmail.$dirty">
                      <b-alert
                        show
                        variant="danger"
                        class="error col mt-1"
                        v-if="!$v.parentsEmail.required"
                      >
                        Polje "Email naslov starša oz. skrbnika" ne sme biti
                        prazno.
                      </b-alert>
                      <b-alert
                        show
                        variant="danger"
                        class="error col mt-1"
                        v-if="!$v.parentsEmail.email"
                      >
                        Polje "Email naslov starša oz. skrbnika" mora biti
                        pravilno izpolnjeno (npr.: ime.priimek@gmail.com).
                      </b-alert>
                    </div>
                  </b-form-group>
                </b-card>
                <b-form-group>
                  <b-input
                    v-model="phone"
                    type="tel"
                    id="phone"
                    name="phone"
                    placeholder="telefonska številka (podatek ni obvezen)"
                  />
                </b-form-group>
                <b-form-group>
                  <b-input
                    v-model="email"
                    type="email"
                    id="email"
                    name="email"
                    placeholder="email naslov"
                    required=""
                  />
                  <div v-if="$v.email.$dirty">
                    <b-alert
                      show
                      variant="danger"
                      class="error col mt-1"
                      v-if="!$v.email.required"
                    >
                      Polje "Email" ne sme biti prazno.
                    </b-alert>
                    <b-alert
                      show
                      variant="danger"
                      class="error col mt-1"
                      v-if="!$v.email.email"
                    >
                      Polje "Email" mora biti pravilno izpolnjeno (npr.:
                      ime.priimek@gmail.com).
                    </b-alert>
                  </div>
                </b-form-group>
                <b-form-group>
                  <b-input
                    v-model="password"
                    type="password"
                    id="password"
                    name="password"
                    placeholder="geslo"
                    required=""
                  />
                  <password v-model="password" :strength-meter-only="true" />

                  <div v-if="$v.password.$dirty">
                    <b-alert
                      show
                      variant="danger"
                      class="error col mt-1"
                      v-if="!$v.password.required"
                    >
                      Polje "Geslo" ne sme biti prazno.
                    </b-alert>
                    <b-alert
                      show
                      variant="danger"
                      class="error col mt-1"
                      v-if="!$v.password.minLength"
                    >
                      Polje "Geslo" mora vsebovati vsaj
                      {{ $v.password.$params.minLength.min }} znakov.
                    </b-alert>
                  </div>
                </b-form-group>
                <b-form-group>
                  <b-input
                    v-model="passwordRepeat"
                    type="password"
                    id="passwordRepeat"
                    name="passwordRepeat"
                    placeholder="ponovite vpisano geslo"
                    required=""
                  />
                  <div v-if="$v.passwordRepeat.$dirty">
                    <b-alert
                      show
                      variant="danger"
                      class="error col mt-1"
                      v-if="!$v.passwordRepeat.sameAsPassword"
                    >
                      Polje "Ponovitev gesla" mora vsebovati povsem enako geslo,
                      kot ste ga vpisali v polje "Geslo".
                    </b-alert>
                  </div>
                </b-form-group>
                <b-form-group>
                  <b-form-checkbox
                    class="mb-3 mt-2"
                    id="termsAndConditionsAcceptance"
                    v-model="termsAndConditionsAcceptance"
                    name="termsAndConditionsAcceptance"
                  >
                    Strinjam se s
                    <router-link
                      :to="{ name: 'TermsAndConditions' }"
                      target="_blank"
                      active-class="active"
                      exact
                      >Pogoji uporabe
                    </router-link>
                  </b-form-checkbox>

                  <div v-if="$v.termsAndConditionsAcceptance.$dirty">
                    <b-alert
                      show
                      variant="danger"
                      class="error col mt-1"
                      v-if="!$v.termsAndConditionsAcceptance.checked"
                    >
                      Okence "Strinjam se s Pogoji uporabe" mora biti
                      obkljukano.
                    </b-alert>
                  </div>
                </b-form-group>
                <b-button
                  type="submit"
                  class="btn send-btn mb-4 mt-2"
                  @click.prevent.stop="register"
                  >REGISTRIRAJ ME!
                </b-button>

                <div class="mt-2">
                  <a
                    class="btn btn-link"
                    @click.prevent.stop="
                      () => {
                        $router.push({ name: 'SignIn' });
                      }
                    "
                    >Ste že registrirani?</a
                  >
                </div>
              </b-form>
            </div>
          </div>
        </article>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// @ is an alias to /src
import {
  required,
  sameAs,
  minLength,
  numeric,
  email,
  minValue,
  maxValue,
  requiredIf
} from "vuelidate/lib/validators";
import firebase from "firebase";
import { db } from "@/main";
import Password from "vue-password-strength-meter";

export default {
  name: "SignIn",
  components: {
    Password
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      displayName: "",
      yearOfBirth: "",
      parentsName: "",
      parentsEmail: "",
      phone: "",
      email: "",
      password: "",
      passwordRepeat: "",
      allowEmailNotifications: true,
      termsAndConditionsAcceptance: false,
      error: "",
      submitStatus: null,
      isMinor: false
    };
  },
  validations: {
    firstName: {
      required,
      minLength: minLength(3)
    },
    lastName: {
      required,
      minLength: minLength(3)
    },
    displayName: {
      required,
      minLength: minLength(3)
    },
    yearOfBirth: {
      required,
      numeric,
      minLength: minLength(4),
      minValue: minValue(1900),
      maxValue: maxValue(new Date().getFullYear())
    },
    parentsName: {
      required: requiredIf(function(val) {
        console.log("val", val);
        return val._data.isMinor;
      })
    },
    parentsEmail: {
      required: requiredIf(function(val) {
        return val._data.isMinor;
      }),
      email
    },
    email: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(5)
    },
    passwordRepeat: {
      sameAsPassword: sameAs("password")
    },
    termsAndConditionsAcceptance: {
      checked: value => value === true
    }
  },
  beforeCreate() {
    const selectedLang = localStorage.getItem('zapisi_spomina_language');
    if(selectedLang !== null) {
      if(selectedLang === 'EN' && !this.$route.fullPath.includes('/en')) {
        this.$router.push({name: 'EN_' + this.$route.name}, this.$route.params);
      } else if(selectedLang === 'SI' && this.$route.fullPath.includes('/en')) {
        this.$router.push({name: this.$route.name.split('EN_')[1]}, this.$route.params);
      }
    }
  },
  mounted() {},
  methods: {
    async register() {
      const _this = this;
      console.log("register");

      console.log(
        "_termsAndConditionsAcceptance",
        _this.termsAndConditionsAcceptance
      );
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("this.$v.", this.$v);
        this.submitStatus = "ERROR";
        return;
      }

      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then(data => {
          data.user
            .updateProfile({
              displayName: this.displayName
            })
            .then(() => {
              let ref = db.collection("users").doc();
              ref.set({ id: ref.id });

              _this.addUser(data, ref.id).then(() => {
                data.user.sendEmailVerification().then(
                  function() {
                    if (_this.isMinor) {
                      _this.sendEmailToParent().then(() => {
                        console.log("send Verification");
                        _this.$router.push({
                          name: "SignIn",
                          params: { justRegistered: "true" }
                        });
                      });
                    } else {
                      console.log("send Verification");
                      _this.$router.push({
                        name: "SignIn",
                        params: { justRegistered: "true" }
                      });
                    }
                  },
                  function(error) {
                    console.log("not send Verification", error);
                  }
                );
              });
            });
        })
        .catch(err => {
          console.log("error", err);
          if (err.code && err.code === "auth/email-already-in-use") {
            _this.error =
              "Uporabniški račun za naveden e-poštni naslov že obstaja!";
          } else {
            _this.error =
              "Registracija s posredovanimi podatki ni mogoča! Ste pravilno izpolnili vsa polja?";
          }
        });
    },

    async sendEmailToParent() {
      const _this = this;

      let subject = "Zapisi spomina: Registracija mladoletne osebe";
      let html =
        "Spoštovani,<br/>" +
        "obveščamo vas, da se je " +
        _this.firstName +
        " " +
        _this.lastName +
        " (" +
        _this.email +
        ") registriral v aplikacijo Zapisi spomina in vas navedel kot starša/skrbnika/zakonitega zastopnika.<br/><br/>To obvestilo ste prejeli, ker je v pogojih uporabe aplikacije in skladno s zakonom določeno, da morajo biti o registraciji mladoletne osebe v aplikacijo obveščeni njegovi oz. njeni starši/skrbniki/zakoniti zastopniki.<br/><br/>" +
        '<a href="https://zapisi-spomina.dobra-pot.si">Aplikacija Zapisi spomina</a> je namenjena beleženju živega ustnega izročila ljudi, zlasti starejših, in njihovih spominov o življenju nekoč. Vsebine se beležijo z namenom, da se ohranijo za danes in jutri.<br/><br/>' +
        "Če se kot skrbnik, starš oziroma zakoniti zastopnik z omenjeno registracijo mladoletne osebe ne strinjate, nam to sporočite na info@dobra-pot.si, da bomo lahko ustrezno ukrepali.<br/><br/>" +
        "Lep pozdrav,<br/>" +
        "ekipa Zavoda Dobra pot";

      console.log("Creating record for email!");
      let ref = db.collection("emails").doc();
      return ref
        .set({
          id: ref.id,
          to: _this.parentsEmail,
          message: {
            subject: subject,
            html: html
          }
        }) // set new document ID
        .then(() => {
          console.log("Record for email created!");
        });
    },

    addUser(data, id) {
      const _this = this;

      const user = db.collection("users").doc(id);

      return user
        .update({
          id: id,
          uid: data.user.uid,
          firstName: _this.firstName,
          lastName: _this.lastName,
          displayName: data.user.displayName,
          yearOfBirth: _this.yearOfBirth,
          parentsName: _this.parentsName,
          parentsEmail: _this.parentsEmail,
          phone: _this.phone,
          email: data.user.email,
          allowEmailNotifications: true,
          termsAndConditionsAcceptance: _this.termsAndConditionsAcceptance,
          createdAt: new Date(),
          updatedAt: new Date(),
          lastLoginAt: null,
        })
        .then(function() {
          console.log("Record written");
        })
        .catch(function(error) {
          console.error("Error adding Record: ", error);
        });
    },

    calculateAge() {
      const _this = this;

      if (!_this.yearOfBirth) {
        _this.isMinor = false;
        return;
      }

      let age =
        parseInt(_this.moment().format("YYYY")) - parseInt(_this.yearOfBirth);
      console.log("age", age);
      if (age > 0 && age < 18) {
        _this.isMinor = true;
      } else {
        _this.isMinor = false;
      }
    }
  }
};
</script>
