<template>
  <b-container class="authPages">
    <b-row>
      <b-col lg="8" offset-lg="2">
        <article class="single-blog contact-us">
          <div class="post-content">
            <div class="">
              <h3 class="mt-2">Novo ali pozabljeno geslo</h3>

              <b-alert variant="danger" :show="error.length > 0"
                >{{ error }}
              </b-alert>

              <p class="p-2">
                Za nastavitev novega gesla vpišite vaš e-poštni naslov. E-poštni
                naslov mora biti enak, kot ste ga uporabili za registracijo.
              </p>

              <b-form class="form-horizontal contact-form">
                <b-form-group>
                  <b-input
                    v-model="username"
                    type="text"
                    id="email"
                    name="email"
                    placeholder="uporabniško ime (email)"
                    required=""
                  />
                </b-form-group>
                <b-button
                  type="submit"
                  class="btn send-btn mb-4 mt-2"
                  @click.prevent.stop="login"
                  >PONASTAVITEV GESLA
                </b-button>

                <div class="mt-2">
                  <a
                    class="btn btn-link"
                    @click.prevent.stop="
                      () => {
                        $router.push({ name: 'SignIn' });
                      }
                    "
                    >Na obrazec za prijavo</a
                  >
                </div>
                <div class="mt-2">
                  <a
                    class="btn btn-link"
                    @click.prevent.stop="
                      () => {
                        $router.push({ name: 'Register' });
                      }
                    "
                    >Še niste registrirani?</a
                  >
                </div>
              </b-form>
            </div>
          </div>
        </article>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// @ is an alias to /src
import firebase from "firebase";
import { db } from "../../main";

export default {
  name: "ForgottenPassword",
  components: {},
  data() {
    return {
      username: "",
      password: "",
      error: "",
      user: {}
    };
  },
  mounted() {},
  methods: {
    login() {
      const _this = this;
      console.log("login", _this);

      firebase
        .auth()
        .sendPasswordResetEmail(_this.username)
        .then(data => {
          console.log("data", data);

          _this.$swal(
            "Na e-poštni naslov " +
              _this.username +
              " smo vam poslali poslali povezavo, preko katere si lahko ponastavite geslo.",
            "",
            "success"
          );
        })
        .catch(err => {
          console.log("error", err);
          _this.error =
            "Ponstavitev gelsa za uporabniški račun " +
            _this.username +
            " ni mogoča!";
        });
    },

    updateUser(data, recordId) {
      const _this = this;

      console.log("recordId", recordId);

      db.collection("users")
        .doc(recordId)
        .update({
          lastLoginAt: new Date()
        })
        .then(function() {
          console.log("Record updated");
          _this.$router.push({ name: "MyPosts" });
        })
        .catch(function(error) {
          console.error("Error adding Record: ", error);
        });
    }
  }
};
</script>
